import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import projectsStyles from "../styles/pages/projects.module.css"

import babaeAtBarilScene from "../images/babae-at-baril-scene.jpg"
import babaeAtBarilPoster from "../images/babae-at-baril-poster.jpg"
import deadKidsScene from "../images/dead-kids-scene.jpg"
import deadKidsPoster from "../images/dead-kids-poster.jpg"
import tayoSaHulingBuwanNgTaonScene from "../images/tayo-sa-huling-buwan-ng-taon-scene.jpg"
import tayoSaHulingBuwanNgTaonPoster from "../images/tayo-sa-huling-buwan-ng-taon-poster.jpg"

const ProjectsPage = () => (
  <Layout location="projects">
    <SEO title="Projects" />
    <div className={ projectsStyles.projects }>
        <div className={ projectsStyles.project }>
            <div className={ projectsStyles.left }>
                <div className={ projectsStyles.poster }>
                    <img src={ babaeAtBarilPoster } alt=""/>
                </div>
            </div>
            <div className={ projectsStyles.right }>
                <div className={ projectsStyles.scene }>
                    <img src={ babaeAtBarilScene } alt=""/>
                    <a href="https://www.youtube.com/watch?v=TVLd_Hmyg0w">Babae At Baril (2019)</a>
                    <p>Work Scope: Post-Production & Visual Effects</p>
                </div>
            </div>
        </div>
        <div className={ projectsStyles.project }>
            <div className={ projectsStyles.left }>
                <div className={ projectsStyles.scene }>
                    <img src={ deadKidsScene } alt=""/>
                    <a href="https://www.youtube.com/watch?v=OFKnelgh12I">Dead Kids (2019)</a>
                    <p>Work Scope: Post-Production & Visual Effects</p>
                </div>
            </div>
            <div className={ projectsStyles.right }>
                <div className={ projectsStyles.poster }>
                    <img src={ deadKidsPoster } alt=""/>
                </div>
            </div>
        </div>
        <div className={ projectsStyles.project }>
            <div className={ projectsStyles.left }>
                <div className={ projectsStyles.poster }>
                    <img src={ tayoSaHulingBuwanNgTaonPoster } alt=""/>
                </div>
            </div>
            <div className={ projectsStyles.right }>
                <div className={ projectsStyles.scene }>
                    <img src={ tayoSaHulingBuwanNgTaonScene } alt=""/>
                    <a href="https://www.youtube.com/watch?v=9sGA_OTREzw">Tayo Sa Huling Buwan Ng Taon (2019)</a>
                    <p>Work Scope: Post-Production & Digital Imaging</p>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)

export default ProjectsPage